<template>
  <div class="fill-height">
    <Navbar />
    <CurrentBroadcasts />
    <v-dialog
      :value="user.is_on_grace_period && dialog && !isAssociate"
      persistent
      max-width="430"
    >
      <v-card>
        <v-card-title class="headline">{{
          $t("payment.notificationAlert")
        }}</v-card-title>
        <v-card-text class="vcardtext"
          ><v-alert v-if="user.has_failed_payment" dense outlined type="error">
            {{ user.failed_payment_reason }}</v-alert
          >{{ $t("payment.gracePeriondMessage") }}
          <!-- {{ user.grace_period_days }}
          {{ $t("payment.days") }}-->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green accent-4"
            class="green darken-4 green-gradient white--text"
            @click="
              dialog = false;
              $router.push('/payment-details');
            "
          >
            {{ $t("payment.paynow") }}
          </v-btn>
          <v-btn
            color="red accent-4"
            class="red darken-4 red-gradient white--text"
            @click="dialog = false"
          >
            {{ $t("payment.close") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main class="custom-height panel-left light-green lighten-5">
      <v-container fluid class="light-green lighten-5 pt-5 pl-5 pr-5 pb-5">
        <v-row v-if="$route.name == 'CoreBuyingMember' || $route.name == 'CoreBuying'">
          <v-col primary cols="12" sm="12">
            <v-main class="panel-right">
              <router-view></router-view>
            </v-main>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col primary cols="12" sm="6">
            <Posts />
          </v-col>
          <v-col primary cols="12" sm="6">
            <v-main class="panel-right">
              <router-view></router-view>
            </v-main>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <Footer />
  </div>
</template>

<script>
import Navbar from "../components/layout/Navbar";
import Footer from "../components/layout/Footer";
import CurrentBroadcasts from "../components/CurrentBroadcasts";
import Posts from "../components/Posts";
import "@/plugins/echo";
// Janus Related files
import "@/plugins/yoyo";
import { mapGetters } from "vuex";

export default {
  components: {
    Navbar,
    Footer,
    CurrentBroadcasts,
    Posts,
  },

  data() {
    return {
      dialog: true,
    };
  },

  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
  },
  mounted() {
    window.addEventListener(
      "storage",
      () => {
        window.location.reload();
      },
      false
    );

    if (!localStorage.getItem("user_data")) {
      localStorage.removeItem("access_token");
      window.location.reload();
    }
  },
};
</script>
